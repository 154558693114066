/**
 * Forgot password - start password reset process.
 */
import { Auth } from 'aws-amplify';

import PasswordValidFeedback from '@/components/PasswordValidFeedback.vue';

import { passwordIsValid } from '@/util/auth';

export default {
  name: 'ForgotPassword',
  components: { PasswordValidFeedback },
  data() {
    return {
      email: '',
      emailState: null,
      emailDisabled: false,
      initiateState: 0,
      code: '',
      codeState: null,
      password: '',
      passwordState: null,
      newPasswordState: 0,
      saving: false,
      gotCode: false,
      formValid: false,
      // Note - password policy should be updated to match Cognito settings.
      // See also /views/user/Profile.js
      passwordPolicy: {
        MinimumLength: 8,
        RequireUppercase: true,
        RequireLowercase: true,
        RequireNumbers: true,
        RequireSymbols: true,
        TemporaryPasswordValidityDays: 7,
      },
    };
  },
  computed: {
    /**
     * Generates an object which can be used to determine whether the password is valid or not.
     */
    newPasswordIsValid: function() {
      return passwordIsValid(this.passwordPolicy, this.password);
    },
  },
  methods: {
    /**
     * Check whether the e-mail input is valid or not.
     * @param {String} ref
     */
    checkFormValid(ref) {
      if (ref === 'password') {
        this.passwordState =
          this.newPasswordIsValid && this.newPasswordIsValid.All;
      } else {
        this[`${ref}State`] = this.$refs[ref].checkValidity();
      }
      if (this.gotCode) {
        this.initiateState = 0;
        this.formValid =
          this.emailState && this.codeState && this.passwordState;
      } else {
        this.formValid = this.emailState;
      }
    },

    /**
     * Handle form submission.
     */
    async go() {
      if (this.gotCode) {
        await this.setPassword();
      } else {
        await this.getCode();
      }
    },

    /**
     * Cause AWS Cognito to send a password reset verification code to the specified e-mail address.
     */
    async getCode() {
      this.saving = true;
      await Auth.forgotPassword(this.email)
        .then(() => {
          this.initiateState = 1;
          this.emailDisabled = true;
          this.gotCode = true;
        })
        .catch(() => {
          this.initiateState = 2;
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Set a new password for the user.
     */
    async setPassword() {
      this.saving = true;
      await Auth.forgotPasswordSubmit(this.email, this.code, this.password)
        .then(() => {
          this.newPasswordState = 1;
        })
        .catch(() => {
          this.newPasswordState = 2;
        })
        .finally(() => {
          // this.saving = false;
        });
    },
  },
};
